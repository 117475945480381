import React from "react";
import styled from "styled-components";
import { getUserSession, clearUserSession } from "../utils/storage";
import { Link, useNavigate } from "react-router-dom";

const SidebarContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100px;
  background-color: #800020; /* Vinho */
  padding: 20px;
  display: flex;
  flex-direction: column;
  transform: translateX(${(props) => (props.isOpen ? "0" : "-100%")});
  transition: transform 0.3s ease-in-out;
`;

const MenuItem = styled(Link)`
  color: white;
  margin: 15px 0;
  font-size: 18px;
  text-decoration: none;
  &:hover {
    color: #00BFFF;
  cursor: pointer;

  }
`;

const ToggleButton = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  background-color: #800020;
  padding: 10px;
  color: white;
  cursor: pointer;
`;

const Sidebar = () => {
  const { user } = getUserSession();
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate(); // Para redirecionamento

  // Função de logout para remover o usuário da sessão
  const handleLogout = () => {
    setIsOpen(false)
    clearUserSession(); // Limpar o localStorage
    navigate("/login"); // Redireciona para a página de login
  };

  if (user && user.perfil !== "Admin") {
    return (
      <>
        <ToggleButton onClick={() => setIsOpen(!isOpen)}>Menu</ToggleButton>
        <SidebarContainer isOpen={isOpen}>
          <MenuItem onClick={() => setIsOpen(false)} to="/home">
            Home
          </MenuItem>
          <MenuItem  onClick={() => setIsOpen(false)} to="/mapa-mesas">
            Eventos - mesas
          </MenuItem>
          <MenuItem  onClick={() => setIsOpen(false)} to="/minhas-compras">
            Meus ingressos
          </MenuItem>
          <MenuItem as="div" onClick={()=>handleLogout()} to="/login">
            Deslogar
          </MenuItem>
        </SidebarContainer>
      </>
    );
  }

  if (!user) {
    return (
      <>
        <ToggleButton onClick={() => setIsOpen(!isOpen)}>Menu</ToggleButton>
        <SidebarContainer isOpen={isOpen}>
          <MenuItem onClick={() => setIsOpen(false)} to="/login">
            Login
          </MenuItem>
          <MenuItem onClick={() => setIsOpen(false)} to="/home">
            Home
          </MenuItem>
          <MenuItem onClick={() => setIsOpen(false)} to="/register">
            Cadastro
          </MenuItem>
        </SidebarContainer>
      </>
    );
  }

  return (
    <>
      <ToggleButton onClick={() => setIsOpen(!isOpen)}>Menu</ToggleButton>
      <SidebarContainer isOpen={isOpen}>
        <MenuItem onClick={() => setIsOpen(false)} to="/home">
          Home
        </MenuItem>
        <MenuItem  onClick={() => setIsOpen(false)} to="/minhas-compras">
            Meus ingressos
          </MenuItem>
        <MenuItem onClick={() => setIsOpen(false)} to="/mapa-mesas">
            Eventos - mesas
          </MenuItem>
        {/* <MenuItem onClick={() => setIsOpen(false)} to="/admin-events">
          Gerenciar Eventos
        </MenuItem> */}
        <MenuItem onClick={() => setIsOpen(false)} to="/realizar-vendas">
         Realizar Vendas
        </MenuItem>
        <MenuItem onClick={() => setIsOpen(false)} to="/dashboard">
         Relatórios - Mesas
        </MenuItem>
        <MenuItem onClick={() => setIsOpen(false)} to="/ingressos">
         Relatórios - Ingressos
        </MenuItem>
        <MenuItem onClick={() => setIsOpen(false)} to="/lista-vendas">
         Vendas
        </MenuItem>
        <MenuItem onClick={() => setIsOpen(false)} to="/lista-usuarios">
         Usuários
        </MenuItem>
        <MenuItem onClick={() => setIsOpen(false)} to="/ingressosUsuario">
         Entrega Ingressos
        </MenuItem>
        <MenuItem as="div" onClick={()=>handleLogout()} to="/login">
          Deslogar
        </MenuItem>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
