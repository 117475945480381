import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Box,
    Button,
    CardContent,
    Collapse,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import api from "../../api/api";

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #800020;
  text-align: center;
  margin-bottom: 20px;
`;

const StyledCard = styled(CardContent)`
  background-color: ${({ entregue }) => (entregue ? "#7FFFD4" : "#fff")};
`;

const formatCpf = (cpf) => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export default function UserCamisas() {
  const [usuarios, setUsuarios] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [expanded, setExpanded] = useState({});
  const [eventos, setEventos] = useState([]);
  const [totalEntregues, setTotalEntregues] = useState(0);
  const [totalNaoEntregues, setTotalNaoEntregues] = useState(0);
  const [filtroPendente, setFiltroPendente] = useState(false);

  useEffect(() => {
    fetchUsuarios();
  }, [search, selectedEvent, filtroPendente]);

  const fetchUsuarios = async () => {
    try {
      const response = await api.post("/api/vendas/camisasVendidas", {
        nome: search,
        idEvent: selectedEvent,
        filtroPendente: filtroPendente,
      });
      setUsuarios(response.data.ingressos);
      setTotalEntregues(response.data.totalIngresosEntregues);
      setTotalNaoEntregues(response.data.totalIngresosNaoEntregues);
    } catch (error) {
      console.error("Erro ao buscar usuários", error);
    }
  };

  useEffect(() => {
    const fetchEventos = async () => {
      try {
        const response = await api.get("/api/events");
        setEventos(response.data);
        setSelectedEvent(response.data[response.data.length - 1]);
      } catch (error) {
        console.error("Erro ao buscar eventos", error);
      }
    };
    fetchEventos();
  }, []);

  const toggleExpand = (cpf) => {
    setExpanded((prev) => ({ ...prev, [cpf]: !prev[cpf] }));
  };

  const confirmarEntrega = async (cpf, nome) => {
    try {
      const userLogado = JSON.parse(localStorage.getItem("user"));
      if (confirmarAcao("Deseja entregar o ingresso(s) de: " + nome)) {
        await api.post("/api/vendas/confirmar-entrega", {
          cpf,
          userLogado: userLogado.email,
        });
        fetchUsuarios();
      }
    } catch (error) {
      console.error("Erro ao confirmar entrega", error);
    }
  };

  function confirmarAcao(message) {
    // eslint-disable-next-line no-restricted-globals
    return confirm(message);
  }

  return (
    <>
      <Title>Entrega de Ingressos</Title>
      <Box p={4}>
        <Grid container spacing={2} mb={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Pesquisar usuário"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="event-select-label">
                Selecione um Evento
              </InputLabel>
              <Select
                labelId="event-select-label"
                value={selectedEvent}
                onChange={(e) => setSelectedEvent(e.target.value)}
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
                {eventos.map((evento) => (
                  <MenuItem key={evento._id} value={evento._id}>
                    {evento.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel >
                Ingressos Pendentes
              </InputLabel>
              <Select
                labelId="pendente-select-label"
                value={filtroPendente}
                onChange={(e) => setFiltroPendente(e.target.value)}
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
                <MenuItem value={true}>
                  <em>SIM</em>
                </MenuItem>
                <MenuItem value={false}>
                  <em>Todos</em>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {selectedEvent.nome}
        <Grid item xs={4} md={2}>
          <Box
            component={Paper}
            elevation={2}
            p={4}
            mb={3}
            sx={{ borderRadius: 2 }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              style={{ color: "green" }}
            >
              Entregues: {totalEntregues}
            </Typography>
          </Box>
          <Grid item xs={4}>
            <Box
              component={Paper}
              elevation={2}
              p={4}
              mb={3}
              sx={{ borderRadius: 2 }}
            >
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ color: "red" }}
              >
                Não Entregues: {totalNaoEntregues}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {usuarios.map((usuario) => {
          const todasEntregues = usuario.camisas.every(
            (camisa) => camisa.statusEntregue
          );
          return (
            // <StyledCard key={usuario.cpf} entregue={todasEntregues}>
            <StyledCard>
              <Box
                component={Paper}
                elevation={2}
                p={2}
                mb={2}
                sx={{ borderRadius: 2 }}
                style={{ backgroundColor: todasEntregues ? "#7FFFD4" : "#fff" }}
              >
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="h6" fontWeight="bold">
                      {usuario.nome}
                    </Typography>
                    <Grid container spacing={1} mt={1}>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          <strong>CPF:</strong> {formatCpf(usuario.cpf)}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          <strong>Apto:</strong> {usuario.apartamento}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          <strong>Torre:</strong> {usuario.torre}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2">
                          <strong>Camisas:</strong> {usuario.camisas.length}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} display="flex" justifyContent="flex-end">
                    <IconButton onClick={() => toggleExpand(usuario.cpf)}>
                      <ExpandMoreIcon
                        sx={{
                          transform: expanded[usuario.cpf]
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
              <Collapse in={expanded[usuario.cpf]}>
                <Box mt={2}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Convidado</TableCell>
                          <TableCell>Tamanho</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {usuario.camisas.map((camisa, index) => (
                          <TableRow key={index}>
                            <TableCell>{camisa.convidado}</TableCell>
                            <TableCell>{camisa.tamanho}</TableCell>
                            <TableCell>
                              {camisa.statusEntregue ? "Entregue" : "Pendente"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {!todasEntregues && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        confirmarEntrega(usuario.cpf, usuario.nome)
                      }
                      sx={{ mt: 2 }}
                    >
                      Confirmar Entrega
                    </Button>
                  )}
                </Box>
              </Collapse>
            </StyledCard>
          );
        })}
      </Box>
    </>
  );
}
